import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {AuthenticationService} from './authentication.service';
import {firstValueFrom, Observable, of, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})

// TODO: Create Interceptor for Token and 401 (**)
export class RemoteService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    @Inject('API_URL') public serverUrl: string
  ) {
  }

  // Generic functions
  public async getRequest(url) {
    const token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    //headers.append('Content-Type', 'application/json');
    //headers.append('Accept', 'application/json');
    //let token = this.auth.getToken()
    //headers.append('Authorization', 'Bearer ' + token);

    const headerObj = {headers: headers};

    url = this.serverUrl + url;
    const result = this.http
      .get<any>(url, headerObj)
      //.map(r => r.json())
      .toPromise();

    // Prevent token expiration
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  public async postRequest(url, data) {
    const token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    //const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    //headers.append('Accept', 'application/json');
    //let token = this.auth.getToken()
    //headers.append('Authorization', 'Bearer ' + token);

    const headerObj = {headers: headers};

    url = this.serverUrl + url;
    const result = this.http
      .post<any>(url, JSON.stringify(data), headerObj)
      //.map(r => r.json())
      .toPromise();

    // Prevent expired tokens
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }


  public async postRequestAsync(url: string, data: any) {
    const token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    const headerObj = {headers: headers};

    url = this.serverUrl + url;
    return await firstValueFrom(this.http.post<any>(url, JSON.stringify(data), headerObj).pipe(catchError(errorResponse => {
      if(Object.hasOwn(errorResponse, "error")) {
        const errorObject = errorResponse.error
        if(Object.hasOwn(errorObject, "ExType") && errorObject.ExType == "BusinessException") {
          const description: string = errorObject.Description;
          return throwError(() => new Error(description));
        }
      }
      throw errorResponse
    })));
  }

}
