import {Component, OnInit} from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import {BaseSubscriptionComponent} from 'src/app/base/base.suscription.component';
import {HttpCodeService} from 'src/app/services/http/http-code.service';
import {lastValueFrom, takeUntil} from 'rxjs';
import {HttpSubticketService} from 'src/app/services/http/http-subticket.service';
import {EditingStartEvent} from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
})
export class TemplatesComponent extends BaseSubscriptionComponent implements OnInit {
  templatesDatasource: DataSource;
  subticketTypesDatasource: any

  contextMenuItems = [];
  editor: any;
  editorToolbar = [
    'background',
    'bold',
    'color',
    {
      name: 'font',
      acceptedValues: [
        null,
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
    },
    'italic',
    'link',
    'image',
    {
      name: 'size',
      acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
    },
    'strike',
    'subscript',
    'superscript',
    'underline',
    'blockquote',
    {
      name: 'header',
      acceptedValues: [false, 1, 2, 3, 4, 5],
    },
    'increaseIndent',
    'decreaseIndent',
    'orderedList',
    'bulletList',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'codeBlock',
    'variable',
    'separator',
    'undo',
    'redo',
    'clear',
  ];

  constructor(private httpCodesService: HttpCodeService, private httpSubticketService: HttpSubticketService) {
    super();
  }

  public ngOnInit(): void {
    this.templatesDatasource = this.buildTemplatesDatasource()
    this.subticketTypesDatasource = this.buildSubticketTypesDatasource()
  }

  public editorInitialized(e) {
    this.editor = e.component;
  }

  public editorChanged(e, data) {
    data.setValue(e.value);
  }

  public overrideOnValueChanged(e) {
    if (e.dataField === 'SubticketTypeId' && e.parentType === 'dataRow') {
      const defaultValueChangeHandler = e.editorOptions.onValueChanged;
      let self = this
      e.editorOptions.onValueChanged = function (args) {
        self.setContextMenuItems(args.value).then(r => console.log(r));
        defaultValueChangeHandler(args);
      }
    }
  }

  async editingStart(e: EditingStartEvent) {
    await this.setContextMenuItems(e.data.SubticketTypeId);
  }

  async setContextMenuItems(subticketTypeId: any) {
    if (subticketTypeId != null) {
      const subticketFields = await lastValueFrom(
        this.httpSubticketService.getSubticketTypeVariables(subticketTypeId).pipe(takeUntil(this.destroyed))
      );

      this.contextMenuItems = subticketFields.map((s) => {
        return {text: s.Code};
      });
    } else {
      this.contextMenuItems = [];
    }
  }

  variableSelected(e) {
    const s = this.editor.getSelection();
    this.editor.insertText(s.index, '{{' + e.itemData.text + '}}');
  }

  public buildTemplatesDatasource() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(this.httpCodesService.getTemplates().pipe(takeUntil(this.destroyed))),

        update: (id, template) =>
          lastValueFrom(this.httpCodesService.updateTemplate(id, template).pipe(takeUntil(this.destroyed))),

        remove: (id) =>
          lastValueFrom(this.httpCodesService.deleteTemplate(id).pipe(takeUntil(this.destroyed))),

        insert: (template) =>
          lastValueFrom(this.httpCodesService.insertTemplate(template).pipe(takeUntil(this.destroyed))),
      }),
    });
  }

  public buildSubticketTypesDatasource(): any {
    return {
      store: new CustomStore({
        loadMode: "raw",
        load: () => lastValueFrom(this.httpCodesService.getSubticketTypes().pipe(takeUntil(this.destroyed)))
      })
    }
  }

  public initNewRow(event) {
    event.data.IsDefault = false;
  }
}
